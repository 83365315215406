/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

$border-color: #dddddd;

$font-size: 13px;

$primary-color: #4b154c;
$primary-color-hover: #3f0e40;

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

@mixin default() {
  color: #5f5f62;
  font-size: $font-size;
}
@mixin allowHover() {
  &:hover {
    cursor: pointer;
  }
}
html {
  max-width: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  .container {
    display: flex;
    height: 100vh;
    max-width: 100vw;
    justify-content: space-between;
  }
}
.nameSection {
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 10px 10px 15px;
  display: flex;
  img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 5px;
  }
  span {
    padding-left: 10px;
  }
}
.hasLink {
  @include allowHover();
}
.isLink {
  @include allowHover();
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.flex {
  display: flex;
}

/* button background color */
.tox .tox-tbtn {
  background-color: #fff;
}

/* button background color (hover) */
.tox .tox-tbtn:hover,
.tox .tox-tbtn:focus {
  background-color: #ebebeb !important;
}

.tox-tinymce {
  border: none !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #f8f8f8 !important;
}
