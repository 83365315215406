$border-color-secondary: #522653;

$layout-breakpoint-mobile: 450px;
$layout-breakpoint-tablet: 667px;

$primary-color: #4b154c;
$primary-color-hover: #3f0e40;

$Small: 576px;
$Medium: 768px;
$Large: 992px;
$ExtraLarge: 1200px;

@mixin channelListCommon() {
  padding: 10px 0px;
  .channelItem {
    font-size: 13px;
    align-items: center;
    display: flex;
    padding: 5px 10px;
    outline: none;
    &:hover:not(.active):not(.labelOnly) {
      background: $primary-color-hover;
      @media (max-width: $Medium) {
        background: transparent;
      }
    }
    &.active {
      background: #1264a3;
      color: #ffffff;
    }
    .channelIcon {
      margin-right: 10px;
    }

    & .channelIcon.addIcon {
      // background: $border-color-secondary;
      border-radius: 2px;
    }
  }
}

@mixin title() {
  color: #d1d2d3;
  font-weight: bold;

  font-size: 16px;
  padding: 10px;
}

.channelListSection {
  color: #c7b7c6;
  width: 100%;
}
.channelListSection.channelList,
.channelListSection.peopleList {
  @include channelListCommon();
  .channelIcon {
    height: 20px;
    width: 20px;
    object-fit: cover;
  }
  .labelOnly .channelIcon {
    height: 15px;
    width: 10px;
  }
}

.channelListSection.appList {
  @include channelListCommon();
  .big-icon {
    height: 20px;
  }
  .channelIcon {
    height: 20px;
    width: 20px;
    object-fit: cover;
  }

  .labelOnly .channelIcon {
    height: 15px;
    width: 10px;
  }
}
.channelListSection.huddle {
  @include channelListCommon();

  border-top: 1px solid $border-color-secondary;
  background: $primary-color;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}
.channelsPeopleWrapper {
  border-left: 1px solid #522653;
  background: $primary-color;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  overflow: hidden;
  position: relative;
  min-width: 200px;

  .nameSection {
    border-bottom: 1px solid #522653;
    color: #ffffff;
  }
  .wrapper {
    bottom: 0px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    top: 0px;
  }
}

.workspace {
  background: $primary-color;
  padding: 10px;

  .myWorkspace {
    align-items: center;
    border-radius: 8px;
    border: 2px solid white;
    color: white;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
  }
}

@media (max-width: $Medium) {
  .channelItem {
    &:hover,
    &:focus,
    &:visited {
      background: transparent;
      outline: none;
    }
  }
  .channelListSection {
    width: 200px;
  }
  .channelsPeopleWrapper {
    display: none;
  }
  .channelsPeopleWrapper.active {
    display: block;
    left: 50px;
    bottom: 0;
    top: 0;
    position: absolute;
    z-index: 4;
    min-height: 100vh;
  }
}
.extraLabel {
  padding-left: 10px;
  font-size: 12px;
  color: #c7b7c6;
}
