$border-color: #dddddd;
$border: 1px solid $border-color;

$font-size: 13px;

$width-channelList: 300px;

$secondary-color: white;
$tertiary-color: #f8f8f8;

$Small: 576px;
$Medium: 768px;
$Large: 992px;
$ExtraLarge: 1200px;

// @media screen and (max-width: $Medium) {
//   .channelView {
//     width: calc(100% px);
//     img {
//       height: auto;
//       max-width: 100%;
//     }
//   }
// }

@mixin paragraph() {
  line-height: 18px;
  font-size: 13px;
  @media screen and (max-width: $Medium) {
    font-size: 13px;
    line-height: 20px;
  }
}

@mixin title() {
  color: #d1d2d3;
  font-weight: bold;

  font-size: 16px;
  padding: 10px;
}

.channelView {
  // @media screen and (max-width: $Medium) {
  //   width: calc(100% - 40px);
  // }
  width: 100%;
  // border: 1px solid red;
  position: relative;
  background: $secondary-color;

  .col:nth-child(2) {
    padding: 0px 0px 0px 5px;
  }
  .floatingTime {
    width: 100%;
    border-bottom: $border;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    .floatingTimeWrapper {
      justify-content: center;
      left: 0;
      right: 0;
      display: flex;
      display: flex;
      top: -14px;
      position: absolute;
    }
    .timeCapsule {
      border: $border;
      padding: 5px 10px 5px 10px;
      border-radius: 20px;
      z-index: 2;
      bottom: -1px;
      background: #ffffff;
    }
  }

  .nameSection {
    color: #1d1c1d;
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
    .navigationIcon {
      display: none;

      @media screen and (max-width: $Medium) {
        display: block;
      }
    }
    .channelIcon {
      margin-right: 10px;
    }
  }
  .singleMessage {
    @include paragraph();
    height: auto;
    // border:1px solid red;
    display: flex;
    padding: 10px 10px 10px 10px;
    .username {
      color: #1d1c1d;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
    .avatarContainer {
      width: 50px;
      height: 50px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .userMessageTime {
      font-size: 12px;
      color: #616061;
    }
    .message {
      @include paragraph();
      // padding-top: 5px;
      color: #4a494a;
      .colWrapper {
        display: flex;
        padding-top: 10px;
      }
      .link {
        color: #1264a3;
        text-decoration: none;
        display: block;
      }
      .borderLeft {
        display: flex;
        background: $border-color;

        width: 4px;
        border-radius: 10px;
      }
      .metaContainer {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        max-width: 550px;
        .previewImage {
          max-width: 550px;
          padding-top: 10px;
          img {
            // height: 200px;
            width: 100%;

            // @media screen and (max-width: $ExtraLarge) {
            //   height: auto;
            //   width: 100%;
            // }
          }
        }

        .metaLogoSiteName {
          display: flex;
          align-items: center;
          img {
            height: 15px;
          }
          .metaName {
            padding-left: 5px;
            color: black;
            font-weight: bold;
          }
        }

        .linkTitle {
          font-weight: bold;
          // color: #1264a3;
          padding: 5px 0;
          &:hover {
            text-decoration: underline;
            text-decoration-color: #1264a3;
          }
        }
        .description {
          @include paragraph();
        }
      }
    }
  }

  .wrapper {
    position: relative;
    top: 0px;
    bottom: 0px;
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.channelIntro {
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (max-width: $Medium) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
.ChannelIntroIconWrapper {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  // justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
.title:not(.isLink) {
  color: #1d1c1d;
  font-weight: bold;
  line-height: 18px;
}
